import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {UserSession} from '@compt/types/account';
import {COMPT_API_BASE_PATH, KEEP_UNUSED_DATA_TIME} from '@compt/utils/consts';
import {SecurityHelpers} from '@compt/utils/security-helpers';

export enum ComptRTKTags {
  USER_SESSION = 'UserSession',
  STIPEND_ALLOTMENTS = 'StipendAllotments',
  STIPEND_EXPENSES = 'StipendExpenses',
  STIPEND_EXPENSE = 'StipendExpense',
  BUSINESS_EXPENSE_REPORT_LIST = 'BusinessExpenseReportList',
  BUSINESS_EXPENSE_REPORT = 'BusinessExpenseReport',
  BUSINESS_EXPENSE_LIST = 'BusinessExpenseList',
  ADMIN_BUSINESS_EXPENSE_LIST = 'AdminBusinessExpenseList',
  ADMIN_BUSINESS_EXPENSE_REPORT_LIST = 'AdminBusinessExpenseReportList',
  TEAM_RECOGNITION_LIST = 'TeamRecognitionList',
}

const currentEnv = import.meta.env.MODE;

export const comptApiSlice = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(ComptRTKTags),
  keepUnusedDataFor: currentEnv === 'test' ? 0 : KEEP_UNUSED_DATA_TIME,
  baseQuery: fetchBaseQuery({
    baseUrl: COMPT_API_BASE_PATH,
    prepareHeaders: (headers) => {
      headers.set('X-CSRFToken', SecurityHelpers.getCSRFToken());
    },
  }),
  endpoints: (builder) => ({
    getSession: builder.query<UserSession, void>({
      providesTags: [ComptRTKTags.USER_SESSION],
      query: () => '/me',
    }),
  }), // Additional endpoints to be injected in their respective slices.
});

export const {useGetSessionQuery} = comptApiSlice;
